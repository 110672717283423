import React from 'react'
import {setCookie} from "../Scripts";
import {Link} from 'react-router-dom';
import $ from 'jquery';
import styled from 'styled-components'
import { Wallet2} from '@styled-icons/bootstrap/Wallet2'

const ico_height = '21px';

const WalletIco = styled(Wallet2)`
      height:${ico_height}
    `;


class Logo extends React.Component{


    go_to_projects(){


    }
    render() {

        return (

            <div>
                <div id="mobile">




                        <div className="nav-container">

                        <div className="hamburger_container"
                             onClick={(e) => {

                                 $('.hamburger-lines').addClass('asdasdasd').click(function(){

                                     if($(this).hasClass('open')){
                                         $('#mobilemenu').css('display','none');
                                         $(this).removeClass('open');
                                     } else {
                                         $(this).addClass('open');
                                         $('#mobilemenu').css('display','block');
                                     }

                                 });


                             }}
                        >

                        <div className="hamburger-lines">
                            <span className="line line1"></span>
                            <span className="line line2"></span>
                            <span className="line line3"></span>
                        </div>
                    </div>
                        </div>


                    <div id="mobilemenu">

                                <div className="menu_icons">
                                    {!this.props.hide_navigation ?

                        <ul>
                            <li>
                        <Link className="first" to="/projects"><span className="ico"></span>&nbsp;&nbsp;Projects</Link>
                        <Link to="/profile"><span className="ico account"></span>&nbsp;&nbsp;Profile</Link>
                        <Link to="/wallet"><span className="wallet_ico"><WalletIco /></span>&nbsp;&nbsp;&nbsp;Wallet</Link>

                            </li>
                        </ul> :


                                        <ul>
                                            <li>
                                                <Link className="first" to="/login">Login</Link>
                                                <Link to="/register">Register</Link>
                                                <Link to="/forgotpassword">Reset Password</Link>

                                            </li>
                                        </ul>
                                    }
                    </div>

                    </div>



                    <div className="moblogo">
                        <Link to={this.props.hide_navigation ? "/login" : "/projects"}>
                        <img src="/logo.png" alt=""/>
                        </Link>
                    </div>


                </div>
            <div id="desktop">
        <div className="logo">

            <span className="pro_badge">
                PRO
            </span>
            <Link to={this.props.hide_navigation ? "/login" : "/projects"}>
                <img src="/logo.png" alt=""/>
            </Link>
            <div className="txt">
AdTest AI
            </div>
        </div>


                {!this.props.hide_navigation ?
<div>



                    <div id="leftbar_buttons">

    <ul>
        <li>
            <Link to="/projects"><span className="ico projects"></span>&nbsp;&nbsp;Projects
            </Link>
            </li>

        <li>

            <Link to="/profile"><span className="ico account"></span>&nbsp;&nbsp;Profile</Link>
        </li>

        <li>
        <Link to="/wallet"><span className="wallet_ico"><WalletIco /></span>&nbsp;&nbsp;&nbsp;Wallet</Link>
        </li>

    </ul>

</div>

    <div id="create_btn_top">

        <li>
            <Link to="/createproject"><span className="ico projects"></span>+ Create Project
            </Link>
        </li>
    </div>

</div>



                    : ''}
                <div id="bottom_icons">
                    <div className="centerer">
                    <ul>
                        <li><a href="mailto:support@adtest.ai"><span className="ico support_ico"></span>Support</a></li>
                        {!this.props.hide_navigation ?

                        <li><a href=""                                                         onClick={(event) => {

                event.preventDefault();

                            setCookie("token","");
                            window.location.href="/Login";

                        }}><span className="ico signout_ico"></span>Sign Out</a></li>
                            : ''}
                    </ul>
                </div>
                </div>



            </div>

            </div>
        )
    }

}

export default Logo;