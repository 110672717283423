//Home.js
import React, {useEffect, useMemo, useState} from 'react'
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5';
import Navbar from './Navbar'
import {Link, useNavigate} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import Login from "./Login";
import CreateProject from "./CreateProject";
import Popup from "reactjs-popup";
import PurpleLoader from './components/PurpleLoader';



const Projects = () => {
    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);
    useEffect(() => {
        scripts.fix_page_contents_class_height(true);

    },[]);

    const [overide_copy_msg, SetOverideCopyMsg] = useState('');

    const [is_submitting_copy, SetSubmittingCopy] = useState(false);

    const [project_status_to_show, SetProjectStatusToShow] = useState('all');

    const [is_submitting_change_status, SetSubmittingChangeStatus] = useState(false);

    function show_modal_loader(){
        $('.inmodal .content').css('display','none');
        $('.inmodal .actions').css('display','none');
        $('.inmodal .modal_loader').css('display','block');
    }
    function hide_modal_loader(){
        $('.inmodal .content').css('display','block');
        $('.inmodal .modal_loader').css('display','none');
    }
    function get_datatbl_ajax_url(new_status){


        var current_status = project_status_to_show;
        try {

            if(new_status){
                current_status = new_status;
            }
        }
        catch(err){

        }
        return window.api_url+"adtest/main/main_list_projects?"+scripts.get_api_url_suffix()+"&project_status="+current_status;
    }
    window.show_react_copy_popup = function(survey_id){


        SetPopupProjectId(survey_id);
        $('span.show_copy_project').click();



    }

    window.show_react_change_status_popup = function(survey_id,should_activate){


        SetPopupProjectId(survey_id);
        SetPopupShouldActive(should_activate);
        $('span.show_change_status_project').click();


    }

    window.react_navigate = function(path){
        navigate(path);
    }

    const [popup_edited_project_id, SetPopupProjectId] = useState(0);
    const [popup_edited_project_title, SetPopupProjectTitle] = useState(0);
    const [popup_edited_should_activate, SetPopupShouldActive] = useState(0);



    useEffect(() => {

        if(popup_edited_project_id){
            $('.dataTable tbody tr').each(function(){

                var tbl_project_id = $(this).find('td').eq(3).text();



                if(tbl_project_id==popup_edited_project_id){
                    var project_title = $(this).find('td').eq(4).text();

                    SetPopupProjectTitle(project_title);


                }

            });
        }

    },[popup_edited_project_id]);


    useEffect(() => {


        const dataTable = $('#datatable').DataTable({
                serverSide: true,
                paging: true,
                order: [[2, 'desc']],

"pageLength" :  25,

            pagingType: 'simple_numbers',
                processing: true,
                ajax: {
                    url: get_datatbl_ajax_url(),
                    type: 'POST'
                },
                columns: [

                    {"bSortable": false,
                    },
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,

                    {"bSortable": false,
                        'className':'table-col-center'
                    },
                    null,

                    {"bSortable": false,
                    'className':'table-col-center'
                    }

                ,
                    {"bSortable": false,
                        'className':'table-col-center'
                    },
                    {"bSortable": false,
                        'className':'table-col-center'
                    },
                    {"bSortable": false,
                        'className':'table-col-center'
                    },
                    {"bSortable": false,
                        'className':'table-col-center'
                    }
                ],
            'initComplete' : function() {


                var input = $('.dataTables_filter input').unbind();
                var self = this.api(),
                    $searchButton = $('<button>')
                        .addClass('pull-right')
                        .addClass('btn')
                        .addClass('leftmarginbtn')
                        .addClass('btn-primary')
                        .text('search')
                        .click(function() {
                            self.search(input.val()).draw();
                        }),
                    $clearButton = $('<button>')
                        .addClass('pull-right')
                        .addClass('btn')
                        .addClass('leftmarginbtn')
                        .addClass('btn-primary')
                        .text('clear')
                        .click(function() {
                            input.val('');
                            $searchButton.click();
                        })
                $('.dataTables_filter').prepend($searchButton, $clearButton);

                input.keyup(function(e){

                    if(e.keyCode==13){

                        $searchButton.click();


                    }

                });



            }

            });

            return () => {
                dataTable.destroy();
            };

        },[]
    );

    const go_to_create = () => {
        navigate('/createproject');

    };


    function set_data_tbl(e,new_status) {

        $('.buttons_row .action_btn').removeClass('active');


        SetProjectStatusToShow(new_status);

        $('.buttons_row #'+new_status+'_btn').addClass('active');

        e.preventDefault();
        $('#datatable').DataTable()
            .ajax.url(
            get_datatbl_ajax_url(new_status)
        )
            .load();


    }

    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo edit_project={popup_edited_project_title} />

                </div>

                <div className="pagecontents">


                    <Popup
                        trigger={<span className="show_copy_project"></span>}
                        modal
                        nested
                    >
                        {close => (
                            <div className="inmodal">
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                                <div className="header">Copy Project {popup_edited_project_title} </div>



                                <div className="modal_loader">
                                    <PurpleLoader height={40} />

                                </div>
                                <div className="content project_copy_content">

                                You are about to copy a project, all responses will be not be copied,
                                    upon finish you you will be taken to the project confirmation page,
                                    are you sure you wish to continue?
                                </div>
                                <div className="actions">

                                    <div className="in_actions">


                                        <button disabled={is_submitting_copy}
                                            className="button select_product project_copy_btn"
                                            onClick={() => {


                                                show_modal_loader();

                                                SetSubmittingCopy(true);
                                                $.ajax({
                                                    url: window.api_url+'adtest/main/adtest_copy_survey?copy_survey_id='+popup_edited_project_id+'&'+scripts.get_api_url_suffix(),
                                                    type: 'get',
                                                    contentType: false,
                                                    processData: false,
                                                    success: function(response){
                                                        SetSubmittingCopy(false);
                                                        try {
                                                            var json = $.parseJSON(response);


                                                            if(json.success){

                                                                close();
                                                                navigate('/confirmation/'+json.new_sid);

                                                            }

                                                        }
                                                        catch(err){
                                                            console.log(err);
                                                        }

                                                    },
                                                    error: function(XMLHttpRequest, textStatus, errorThrown) {

                                                        SetSubmittingCopy(false);
                                                        alert("internet_connnection_problem");


                                                    }
                                                });


                                            }}
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </div>


                            </div>
                        )}
                    </Popup>


                    <Popup
                        trigger={<span className="show_change_status_project"></span>}
                        modal
                        nested
                    >
                        {close => (
                            <div className="inmodal">
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                                <div className="header">{popup_edited_should_activate ? popup_edited_project_title+' Project Activation' : 'Pause Project '+popup_edited_project_title} </div>



                                <div className="modal_loader">
                                    <PurpleLoader height={40} />

                                </div>



                                <div className="content project_copy_content">


                                    {
                                        (
                                        overide_copy_msg ? overide_copy_msg :
                                        (
                                    popup_edited_should_activate ? 'You are about to activate a project, are you sure you wish to Continue?' : 'You are about to pause a project, are you sure you wish to continue?')
                                    )
                                    }
                                </div>
                                <div className="actions">

                                    <div className="in_actions">


                                        <button disabled={is_submitting_change_status}
                                            className="button select_product project_copy_btn"
                                            onClick={() => {

                                                show_modal_loader();
                                                SetSubmittingChangeStatus(true);
                                                $.ajax({
                                                    url: window.api_url+'adtest/main/adtest_publish_or_unpublish_survey?active='+popup_edited_should_activate+"&project_id="+popup_edited_project_id+"&"+scripts.get_api_url_suffix(),
                                                    type: 'get',
                                                    contentType: false,
                                                    processData: false,
                                                    success: function(response){

                                                SetSubmittingChangeStatus(false);
                                                        try {
                                                            var json = $.parseJSON(response);


                                                            if(json.error){

                                                                if(json.error=='max_submissions_already_recorded') {

                                                                    hide_modal_loader();
                                                                    SetOverideCopyMsg('project has reached the required number of completes, please increase or start a new project.');

                                                                } else
                                                                if(json.error=='not_enough_funds'){
                                                                    hide_modal_loader();
                                                                    SetOverideCopyMsg('You do not have enough credits, please contact us.');
                                                                }
                                                                else
                                                                if(json.error=='survey_must_have_targeting'){
                                                                    hide_modal_loader();
                                                                    SetOverideCopyMsg("Project must have targeting to launch. Please edit targeting and try again.");

                                                                }

                                                                else {
                                                                    alert(json.error);
                                                                    close();
                                                                }
                                                            }

                                                            if(json.success){


                                                                $('#datatable').DataTable().ajax.reload();
                                                                close();


                                                                // navigate('/projects');
                                                            }

                                                        }
                                                        catch(err){
                                                            console.log(err);
                                                        }

                                                    },
                                                    error: function(XMLHttpRequest, textStatus, errorThrown) {

                                                        SetSubmittingChangeStatus(false);
                                                        alert("internet_connnection_problem");


                                                    }
                                                });




                                            }}
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                    <h1>Projects</h1>


                    <div className="buttons_row notopmargin">




                        <a className="right_btn" onClick={go_to_create}>+ Create New Project</a>
                    </div>
<div className="clear"></div>
                    <div className="card">
                        <div className="card-body">

                            <div className="buttons_row lowerspace">
                                <a id="all_btn"
                                   onClick={(e) => {
                                    set_data_tbl(e,"all");
                                   }}
                                   className="action_btn active" href="">All</a>
                                <a id="live_btn"
                                   onClick={(e) => {
                                       set_data_tbl(e,"live");
                                   }}

                                   className="action_btn" href="">Live</a>
                                <a id="paused_btn"

                                   onClick={(e) => {
                                       set_data_tbl(e,"paused");
                                   }}
                                   className="action_btn"  href="">Paused</a>

                            </div>
                            <div className="clear"></div>

                            <table className="table table-bordered" id="datatable">
                                <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Product</th>
                                    <th>Modified</th>
                                    <th>ID</th>
                                    <th>Project_title</th>
                                    <th>Country</th>
                                    <th>Fee</th>
                                    <th>Target</th>
                                    <th>Achieved</th>
                                    <th>Edit</th>
                                    <th>Copy</th>
                                    <th>Start</th>
                                    <th>Stop</th>
                                    <th>Report</th>

                                </tr>
                                </thead>
                                <tbody></tbody>
                                <tfoot></tfoot>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
            </div>
            )

        }

export default Projects