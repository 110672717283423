import React from "react";
import PurpleLoader from './PurpleLoader';

class PageLoading extends React.Component {
    render() {

        return                             <p>
            <PurpleLoader height={40} />

            Loading...</p>
    }

}

export default PageLoading;